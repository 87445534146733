import React from 'react';
import config from '../../config';
export default function Footer() {
  return (
    <footer id="footer">
      <ul className="Links">
 {config.footerLinks.map(footerLinks => {
          const { name, style, icon, url } = footerLinks;
          return (
            <li>
              <a href={url} className={`icon ${style} ${icon}`}>
              <span>{name}</span> 
              </a>
          </li>           
          );
        })}
      </ul>
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { style, icon, name, url } = social;
          return (
            <li key={url}>
              <a href={url} className={`icon ${style} ${icon}`}>
                <span className="label">{name}</span>
              </a>
            </li>
          );
        })}
      </ul>
      <ul className="copyright">
        <li>&copy; JR Global Consultancy Services Pvt.Ltd</li>
      </ul>
    </footer>
  );
}
