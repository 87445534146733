module.exports = {
  siteTitle: 'JR Global', // <title>
  manifestName: 'JR Global',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-spectral/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'JR GLOBAL',
  subHeading: 'Excellence With Integrity',
  
  
  // Footer Links
  footerLinks: [
    {
      name: 'Home',
      style: 'solid',
      icon: 'fa-home',
      url: '/#'
    },
    {
      name: 'Services',
       style: 'solid',
      icon: 'fa-cog',
      url: '/Services'
    },
    {
      name: 'Enterprise Solutions',
       style: 'solid',
      icon: 'fa-shield-alt',
      url: '/Solutions'
    },
    {
      name: 'Technology Partners',
       style: 'solid',
      icon: 'fa-handshake',
      url: '/Partners'
    },
    {
      name: 'About',
       style: 'solid',
      icon: 'fa-cubes',
      url: '/About'
    },
       {
      name: 'Contact',
       style: 'solid',
      icon: 'fa-info-circle',
      url: '/Contact'
    }
  ],
  // Social Links
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/',
    },
    {
      style: 'brands',
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:test@example.com',
    },
  ],
};
