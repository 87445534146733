import React from 'react';
import { Link } from 'gatsby';
// @ts-ignore
import pic1 from '../assets/img/website-logo.png';

export default function Nav({ onMenuToggle = () => {} }) {
  return (
       <div className="alignleft"><a href='\#'><img className="logoSize" src={pic1} alt="logo" /></a>
    <nav id="nav">
   
      <ul>
        <li className="special">
          
          <a
            href="#menu"
            onClick={e => {
              e.preventDefault();
              onMenuToggle();
            }}
            className="menuToggle"
          >
            <span>Menu</span>
          </a>
          <div id="menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/Services">Services</Link>
              </li>
              <li>
                <Link to="/Solutions">Enterprise Solutions</Link>
              </li>
                 <li>
                <Link to="/Partners">Technology Partners</Link>
              </li>
                 <li>
                <Link to="/About">About Us</Link>
                </li>
                  <li>
                <Link to="/Contact">Contact</Link>
                </li>
            </ul>
            <a
              className="close"
              onClick={e => {
                e.preventDefault();
                onMenuToggle();
              }}
              href="#menu"
            >
              {''}
            </a>
          </div>
        </li>
      </ul> 
      </nav>
      </div>
  );
}
